<template>
	<div class="supplierPage">
		<formRightHeader :isMaterial="true" :navName="$t('materialSupplier')"></formRightHeader>
		<div class="tool-bar">
			<div class="add-product-btn pointer-btn" @click.stop="addSupplier">
			  <i class="iconfont icon-ic_add add-product-icon"></i>
			  <span>{{$t('addBtn')}}</span>
			</div>
			<div v-click-outside="checkOutSide">
			  <div class="form-search-box" v-show="!show" @click="showSearch">
			    <i class="iconfont icon-sousuo1"></i>
			    <span>{{ $t("searchForm") }}</span>
			  </div>
			  <div class="form-search-box" v-show="show">
			    <i class="iconfont icon-sousuo1"></i>
			    <input
			      ref="input"
			      type="text"
			      class="search-input"
			      placeholder="输入供应商名称"
			      v-model="searchName"
			      @keydown.enter="searchSn"
			      @blur="searchSn"
			    />
			  </div>
			</div>
		</div>
		<div class="n-table">
			<a-table
			 size="small"
			 :columns="columns"
			 :data-source="lists"
			 :pagination="pagination"
			 @change="handleTableChange"
			>
				
			</a-table>
		</div>
		<el-dialog
			:visible.sync="dialogVisible"
			width="600px"
		>
			<el-form :model="form" :rules="rules" label-position='left' ref="ruleForm" size="small" label-width="100px" class="infoBox">
				<el-form-item label="供应商名称:" prop="supplier_name">
					<el-input v-if="isEdit" v-model="form.supplier_name"></el-input>
					<label v-else >{{form.supplier_name}}</label>
				</el-form-item>
				<el-form-item label="付款方式:">
					<el-input v-if="isEdit" v-model="form.pay_type"></el-input>
					<label v-else >{{form.pay_type}}</label>
				</el-form-item>
				<el-form-item label="是否含运费:">
					<el-input v-if="isEdit" v-model="form.is_express"></el-input>
					<label v-else >{{form.is_express}}</label>
				</el-form-item>
				<el-form-item label="联系人:">
					<el-input v-if="isEdit" v-model="form.contact_name"></el-input>
					<label v-else >{{form.contact_name}}</label>
				</el-form-item>
				<el-form-item label="联系方式:">
					<el-input v-if="isEdit" v-model="form.contact"></el-input>
					<label v-else >{{form.contact}}</label>
				</el-form-item>
				<el-form-item label="材料类别:">
					<el-input v-if="isEdit" v-model="form.material_type"></el-input>
					<label v-else >{{form.material_type}}</label>
				</el-form-item>
				<el-form-item label="地址:">
					<el-input v-if="isEdit" v-model="form.address"></el-input>
					<label v-else >{{form.address}}</label>
				</el-form-item>
				<el-form-item label="开户行:">
					<el-input v-if="isEdit" v-model="form.bank"></el-input>
					<label v-else >{{form.bank}}</label>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editData" :loading="isLoading" v-if="isEdit && !isAdd">保存</el-button>
				<el-button type="primary" @click="saveData" :loading="isLoading" v-if="isAdd">保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import formRightHeader from "@/components/lists/formRightHeader";
export default {
	components:{
		formRightHeader
	},
	data(){
		return {
			dialogVisible:false,
			isEdit:false,
			form:{},
			lists:[],
			isAdd:false,
			isLoading:false,
			show:false,
			pagination: {
			  current: 1,
			  pageSize: 10,
			  total: 0,
			},
			columns:[
				{
				  title: '供应商名称',
				  dataIndex: "supplier_name",
				  customRender:(text,row)=>{
				  	return(<a-tooltip placement="topLeft" title={text}><span class="col-sql" title={text}>{text}</span></a-tooltip>);
				  }
				},
				{
				  title:'付款方式',
				  dataIndex:"pay_type"
				},
				{
				  title:'是否含运费',
				  dataIndex:'is_express'
				},
				{
				  title: '联系人',
				  dataIndex: "contact_name",
				},
				{
				  title: '联系方式',
				  dataIndex: "contact",
				},
				{
					title:'材料类型',
					dataIndex:"material_type"
				},
				{
				  title: '地址',
				  dataIndex: "address",
				  customRender:(text,row)=>{
				  	return(<a-tooltip placement="topLeft" title={text}><span class="col-sql" title={text}>{text}</span></a-tooltip>);
				  }
				},
				{
				  title: '开户行',
				  dataIndex: "bank",
				  customRender:(text,row)=>{
					  return(<a-tooltip placement="topLeft" title={text}><span class="col-sql" title={text}>{text}</span></a-tooltip>);
				  }
				},
				{
					title:'操作',
					customRender:(text,row)=>{
						return (<div>
							<div class="handleClass" onClick={(e) => this.handleDetail(e,row)}>详情</div>
							<div class="handleClass" onClick={(e) => this.handleEdit(e,row)}>编辑</div>
							<div class="handleClass handleDelete" onClick={(e) => this.handleDelete(e,row)}>删除</div>
						</div>)
					}
				}
			],
			rules: {
			  supplier_name: [
				{ required: true, message: '请输入供应商名称', trigger: 'blur' },
			  ],
			}
		}
	},
	created(){
		this.form = this.formData();
		this.getSupplierList();
	},
	watch:{
	
	},
	methods:{
		formData(){
			return{
				supplier_name:'',
				contact_name:'',
				contact:'',
				address:'',
				bank:'',
				pay_type:'',
				is_express:'',
				material_type:''
			}
		},
		addSupplier(){
			this.dialogVisible = true;
			this.isEdit = true;
			this.isAdd = true;
			this.form = this.formData();
		},
		getSupplierList(){
			 let loading = this.openLoading();
			 let data = {
				 supplier_name:this.searchName,
				 page:this.pagination.current,
				 page_size:this.pagination.pageSize
			 }
			 this.$api.getSupplierList(data).then((res)=>{
				 this.lists = res.data;
				 this.pagination.total = res.total
				 loading.close();
			 })
		},
		handleTableChange(pagination) {
		  this.pagination.current = pagination.current;
		  this.getSupplierList();
		},
		saveData(){
			this.$refs['ruleForm'].validate((valid) => {
			  if (valid) {
				this.isLoading = true;
				this.$api.createSupplier(this.form).then((res)=>{
					this.isLoading = false;
					if(res.status == 1){
						this.dialogVisible = false;
						this.$message.success(res.msg)
						this.getSupplierList();
					}else{
						this.$message.error(res.msg)
					}
				})
			  } else {
				this.$message.success('必填项未填');
				return false;
			  }
			});
		},
		editData(){
			this.$refs['ruleForm'].validate((valid) => {
			  if (valid) {
				this.isLoading = true;
				this.$api.updateSupplier(this.form).then((res)=>{
					this.isLoading = false;
					if(res.status == 1){
						this.dialogVisible = false;
						this.$message.success(res.msg)
						this.getSupplierList();
					}else{
						this.$message.error(res.msg)
					}
				})
			  } else {
				this.$message.error('必填项未填');
				return false;
			  }
			});
		},
		handleDelete(e,row){
			this.$confirm('确认删除？')
			  .then(_ => {
				this.$api.delSupplier({id:row.id}).then((res)=>{
					if(res.status == 1){
						this.$message.success(res.msg);
						this.getSupplierList();
					}else{
						this.$message.error(res.msg)
					}
				})
			  })
			  .catch(_ => {});
			
		},
		handleEdit(e,row){
			this.dialogVisible = true;
			this.isEdit = true;
			this.isAdd = false;
			this.form = row;
		},
		handleDetail(e,row){
			this.dialogVisible = true;
			this.isEdit = false;
			this.isAdd = false;
			this.form = row;
			this.$refs['ruleForm'].resetFields();
		},
		showSearch() {
		  this.show = true;
		  this.$nextTick(() => {
		    this.$refs.input.focus();
		  });
		},
		checkOutSide() {
		 if (this.searchName) {
		    this.show = true;
		  } else {
		    this.show = false;
		  }
		},
		//搜索
		searchSn() {
		  if (this.searchName) {
		    this.pagination.current = 1;
		  }
		  this.getSupplierList();
		},
	}
}
</script>

<style lang="scss">
.supplierPage{
	width: 100%;
	
	.handleClass{
		cursor: pointer;
		display: inline-block;
		margin-right: 10px;
	}
	.handleDelete{
		color: red;
	}
}
</style>
